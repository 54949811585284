import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Bem-vindo ao So de Virada</h1>
        <p>Time de Futebol</p>
        <p>Próximo Jogo: [Data e Hora]</p>
        <p>Último Resultado: [Resultado]</p>
      </header>
    </div>
  );
}

export default App;

